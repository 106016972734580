import { useEffect } from 'react'
import { observer } from 'mobx-react'

import { LocalStorageService } from 'utils/local-storage.utils'

import { useShopStores } from 'shop/hooks/use-store'
import { useNextRouter } from 'shop/hooks/use-next-js-router'

const AffiliateRedirectionPage = observer(() => {
  const { affiliatesStore } = useShopStores()
  const { params } = useNextRouter<{ redirect: string; affiliateToken: string; username: string; productId: string }>()
  const init = async () => {
    const { redirect, affiliateToken, username, productId } = params
    await affiliatesStore.checkAffiliateTracking({ username, slug: productId, product_slug: productId })
    LocalStorageService.setItem('affiliateToken', affiliateToken)
    affiliatesStore.setCampaignID()
    window.location.href = redirect
  }
  useEffect(() => {
    init()
  }, [])
  return null
})

export default AffiliateRedirectionPage

export const getServerSideProps = (context) => {
  if (!context.query.redirect) {
    return {
      notFound: true,
      props: {},
    }
  }

  return {
    props: { redirectPage: true },
  }
}
